<template>
  <patient-profile
    title="View Patient Profile"
    method="view"
  ></patient-profile>
</template>

<script>
import PatientProfile from '@/components/patients/PatientProfile.vue'

export default {
  components: { PatientProfile },
  mounted() {
    if (!this.$route.query.id) {
      this.$router.push({ name: 'list-patients' })
    }
  },
}
</script>
