<template>
  <v-card>
    <v-card-title>
      {{ title }}

      <v-spacer></v-spacer>

      <!-- create quick entry -->
      <btn
        v-if="method === 'view'"
        :disabled="patient.is_deceased"
        label="Create Quick Entry"
        color="warning"
        class="mr-2"
        :icon="icons.mdiClockPlus"
        @click="createQuickEntry"
      ></btn>

      <!-- create encounter -->
      <btn
        v-if="method === 'view'"
        :disabled="patient.is_deceased"
        label="Create Encounter"
        color="success"
        :icon="icons.mdiAccountMultiplePlusOutline"
        @click="createEncounter"
      ></btn>
    </v-card-title>
    <alert
      v-if="patient.no_insurance"
      class="mx-5 mb-5"
    >
      Patient is uninsured or patient’s insurance does not authorize services.
      Please discuss the need for approval or authorization for future visits with facility staff.
      Contact billing with any questions.
    </alert>
    <div
      v-if="method === 'view'"
      class="pb-5"
    >
      <InsuranceAuthorization
        :encounter="encounter"
        :patient-id="patient.id"
      >
      </InsuranceAuthorization>
    </div>

    <v-card-text>
      <v-form
        ref="editPatientForm"
        v-model="formValid"
        :disabled="method === 'view' || !baseDataLoaded"
      >
        <text-field
          v-model="patient.first_name"
          label="First Name"
          counter="50"
          required
          @blur="patient.first_name = $custom.toNameCase(patient.first_name)"
        ></text-field>
        <text-field
          v-model="patient.middle_name"
          label="Middle Name"
          counter="50"
          @blur="patient.middle_name = $custom.toNameCase(patient.middle_name)"
        ></text-field>
        <text-field
          v-model="patient.last_name"
          label="Last Name"
          counter="50"
          required
          @blur="patient.last_name = $custom.toNameCase(patient.last_name)"
        ></text-field>
        <text-field
          v-model="patient.suffix"
          label="Suffix"
          counter="20"
          @blur="patient.suffix = $custom.toUpperCase(patient.suffix)"
        ></text-field>
        <date-input
          ref="dobInput"
          v-model="patient.dob_us"
          label="Date of Birth"
          rules="dob"
          show-age
          required
        ></date-input>
        <text-field
          v-if="method === 'view'"
          label="Gender"
          :value="patient.gender"
        ></text-field>
        <radio-buttons
          v-else
          v-model="patient.gender"
          :items="sex"
          label="Gender"
          required
        ></radio-buttons>
        <text-field
          v-model="patient.ssn_last_4"
          label="Last 4 of SSN"
          rules="exactLength(4)"
          mask="####"
          placeholder="####"
          counter="4"
          numeric
          required
        ></text-field>
        <select-box
          v-model="patient.place_of_service_id"
          :items="computedFacilities"
          label="Facility"
          required
        ></select-box>
        <date-picker
          v-if="method !== 'add'"
          v-model="patient.date_deceased"
          :disabled="deceasedPatient"
          :max="$date().format('YYYY-MM-DD')"
          label="Deceased Date"
          clearable
        ></date-picker>
        <text-field
          v-if="patient.is_deceased"
          v-model="patient.cause_of_death"
          :disabled="deceasedPatient"
          label="Cause of Death"
          counter="255"
          required
        ></text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!-- cancel/ close -->
      <btn
        color="secondary"
        :label="method === 'view' ? 'Close' : 'Cancel'"
        :icon="method === 'view' ? icons.mdiClose : icons.mdiCancel"
        @click="cancelClick"
      ></btn>

      <v-spacer></v-spacer>

      <!-- edit patient -->
      <btn
        v-if="method === 'view'"
        label="Edit Patient"
        :icon="icons.mdiAccountEdit"
        @click="editPatient"
      ></btn>

      <!-- update patient -->
      <btn
        v-if="method === 'edit'"
        :disabled="!formValid"
        label="Update Patient"
        :icon="icons.mdiAccountEdit"
        @click="checkAge(patient.dob_us,true)"
      ></btn>

      <!-- create patient -->
      <btn
        v-if="method === 'add'"
        :disabled="!formValid"
        label="Create Patient"
        :icon="icons.mdiAccountPlus"
        @click="checkAge(patient.dob_us,false)"
      ></btn>
    </v-card-actions>
    <!-- dump object -->
    <div
      v-if="$authUser.user() && $authUser.user().is_superuser"
      class="btn-top-center"
    >
      <btn
        label="Dump"
        :icon="icons.mdiDownload"
        x-small
        color="secondary"
        @click="$store.commit('patients/updatePatient', patient);
                $router.push({ name: 'dump-patient', query: { id: patient.id } })"
      ></btn>
    </div>
  </v-card>
</template>

<script>
import SyncEncounters from '@/mixins/SyncEncounters'
import {
mdiAccountAlert,
mdiAccountEdit,
mdiAccountMultiplePlusOutline,
mdiAccountPlus,
mdiCancel,
mdiClockPlus,
mdiClose,
mdiDownload,
mdiMagnify,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

// Insurance & Authorization
import InsuranceAuthorization from '@/components/notes/insurance/InsuranceAuthorization.vue'

const initialPatient = {
  first_name: '',
  middle_name: '',
  last_name: '',
  suffix: '',
  dob: '',
  dob_us: '',
  gender: '',
  ssn_last_4: '',
  place_of_service_id: '',
  date_deceased: '',
  cause_of_death: '',
  is_deceased: false,
  updated_core: false,
  updated: false,
  wounds: [],
  all_attachments: [],
}

export default {
  components: { InsuranceAuthorization },
  mixins: [SyncEncounters], // Methods: duplicatePatientConfirmation()
  props: {
    title: {
      type: String,
      default: '',
    },

    // Accepted strings 'add', 'edit', 'view',
    method: {
      type: String,
      default: '',
    },
    changed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      patient: { ...initialPatient },
      mounted: false,
      deceasedPatient: false,
      icons: {
        mdiCancel,
        mdiAccountEdit,
        mdiAccountPlus,
        mdiClose,
        mdiAccountMultiplePlusOutline,
        mdiMagnify,
        mdiDownload,
        mdiClockPlus,
        mdiAccountAlert,
      },
    }
  },
  computed: {
    ...mapGetters('encounters', ['baseDataLoaded', 'genders']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('facilities', { facilitiesAll: 'facilities' }),
    ...mapGetters('route', ['lastRoute']),
    ...mapGetters('baseData', ['sex']),
    ...mapFields(['online']),
    computedFacilities() {
      if (this.method === 'view') return this.facilitiesAll
      if (this.method === 'add' || !this.patient?.place_of_service_id) return this.facilities

      const isFacilityInAuth = !!this.$store.getters['auth/getFacilityById'](this.patient.place_of_service_id)?.id
      const currentFacility = this.$store.getters['facilities/getById'](this.patient.place_of_service_id)
      // If the assigned facility isn't in the provider's list of facilities, build a new list which includes the assigned facility
      return (!isFacilityInAuth && currentFacility?.id) ? [...this.facilities, currentFacility] : this.facilities
    },
  },
  watch: {
    'patient.dob_us': {
      handler() {
        const formattedDate = this.$date(this.patient.dob_us).format('YYYY-MM-DD')

        if (this.patient.dob_us?.length === 10 && this.patient.date_deceased && formattedDate > this.patient.date_deceased) {
          // Clear field
          this.$nextTick(() => {
            this.$refs.dobInput.$emit('input', '')
          })

          this.$store.dispatch('notify', { value: "The patient's date of birth must be equal or later than the deceased date.", color: 'error' })
        } else {
          this.patient.dob = formattedDate
        }
      },
    },
    'patient.date_deceased': {
      handler() {
        // Handle entering a deceased date lesser than the patient dob on alredy marked
        if (this.patient.is_deceased
        && this.method === 'edit'
        && this.patient.date_deceased < this.patient.dob) {
          // Set patient as not deceased and clear date_deceased since the date entered is invalid
          this.patient.is_deceased = false
          this.patient.date_deceased = ''

          return this.$store.dispatch('notify', { value: "The patient's deceased date must be later than the date of birth.", color: 'error' })
        }

        // For the first time deceased_date has a date entered we mark the patient as deceased
        if (!this.patient.is_deceased
        && !this.deceasedPatient
        && this.method === 'edit'
        && this.patient.date_deceased !== null
        && this.patient.date_deceased !== '') {
          // Handle entering deceased date lesser than patient dob
          if (this.patient.date_deceased < this.patient.dob) {
            this.patient.date_deceased = ''

            return this.$store.dispatch('notify', { value: "The patient's deceased date must be later than the date of birth.", color: 'error' })
          }

          // Handle decease date change
          this.$root.confirm({
            title: 'Confirm Patient Status Change',
            subTitle: 'Are you sure you want to mark this patient as deceased?',
            body: `<strong>This action can only be reverted by an Administrator and you won't be able to change the Deceased status afterwards.</strong>
            <br><br>Deceased status also prevents the creation of encounters and quick entries for this specific patient.`,
            html: true,
            cancel: 'Cancel',
            confirm: 'Mark as Deceased',
          }).then(result => {
            if (result) this.patient.is_deceased = true
            else {
              this.patient.date_deceased = ''
              this.patient.is_deceased = false
            }
          })
        }
        if (this.patient.date_deceased === null || this.patient.date_deceased === '') {
          this.patient.is_deceased = false
          this.patient.cause_of_death = ''
        }
      },
    },
    'patient.middle_name': {
      handler() {
        this.setMiddleInitial()
      },
    },

    // This tracks if the patient profile has changed in any way
    patient: {
      deep: true,
      handler() {
        if (this.mounted && (this.method === 'edit' || this.method === 'add')) {
          this.$emit('update:changed', true)
        }
      },
    },
  },
  mounted() {
    let patient = {}
    if (this.$route && this.$route.query && this.$route.query.id) {
      patient = this.$store.getters['patients/getById'](this.$route.query.id)
    } else {
      patient = this.$route.params.patient
    }
    this.patient = { ...initialPatient, ...patient }
    if (this.patient.dob) this.patient.dob_us = this.$date(this.patient.dob).format('MM/DD/YYYY')

    this.$nextTick(() => {
      this.mounted = true
    })

    // Check for an existing date of decease on the current patient
    if (this.method === 'edit'
    && this.patient.date_deceased !== null
    && this.patient.date_deceased !== ''
    && this.patient.is_deceased === true
    && this.patient.updated_core === false) {
      this.deceasedPatient = true
    }
  },
  methods: {
    checkAge(dob, isUpdate) {
      // Determine action based on age
      if (this.$custom.ageInYears(dob) < 18) {
        const ageDesc = this.$custom.getAgeDescription(dob)
        const displayAgeDesc = ageDesc === 'newborn' ? 'a newborn' : ageDesc
        this.$root.confirm({
          title: 'Patient Age Check',
          body: `Patient is indicated as being under 18 years of age. Please confirm that this patient is <strong><u>${displayAgeDesc}</u></strong>.`,
          html: true,
          cancel: 'cancel',
          confirm: 'confirm'
        }).then((confirmed) => {
          if (confirmed) {
            isUpdate ? this.updatePatientProfile() : this.createPatientProfile()
          }
        })
      } else {
        // Create/Update the patient immediately if age is >= 18 y/o.
        isUpdate ? this.updatePatientProfile() : this.createPatientProfile()
      }
    },
    isolateProperties(object) {
      const filtered = { ...object }
      Object.keys(filtered).forEach(key => {
        if (key === '_joinData') {
          delete filtered[key]
        } else if (this.$custom.isObject(filtered[key])) {
          filtered[key] = this.isolateProperties(filtered[key])
        }
      })

      return filtered
    },
    cancelClick() {
      /* This isn't needed after the Vue Router 'beforeRouteLeave' guards were added to the Add and Edit patient views
      if (this.formValid && this.method !== 'view') {
        this.$root.confirm({
          title: 'Cancel?',
          body: `Are you sure you wish to cancel ${this.method === 'edit' ? 'the patient profile changes' : 'creating this patient profile'}?`,
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('route/back')
          }
        })
      } else {
        this.$store.dispatch('route/back')
      }
      */
      this.$store.dispatch('route/back')
    },
    createEncounter() {
      // This is '$router.replace' instead of a '$router.push' so '$router.back' directs to the previous page
      this.$router.replace({ name: 'create-encounter', query: { id: this.patient.id } })
    },
    createQuickEntry() {
      // This is '$router.replace' instead of a '$router.push' so '$router.back' directs to the previous page
      this.$router.replace({ name: 'create-encounter', query: { id: this.patient.id, quickEntry: true } })
    },
    createdPatientProfile(id) {
      this.$emit('update:changed', false)
      this.$root.confirm({
        title: 'Created Patient Profile',
        body: 'Patient profile created successfully, click okay to continue',
        cancel: false,
      }).then(() => {
        if (this.lastRoute.name === 'list-patients') {
          this.$store.dispatch('route/back')
        } else {
          // this.$router.push({ name: 'list-patients' })
          this.$router.push({ name: 'view-patient', query: { id } })
        }
      })
    },
    updatedPatient() {
      this.$emit('update:changed', false)
      if (this.online) {
        this.$store.dispatch('notify', { value: 'Patient profile updated successfully.', color: 'success' })
      } else {
        this.$store.dispatch('notify', { value: 'Patient profile updated successfully. Connect online to sync.', color: 'warning' })

        // Set sync status to false, user would need to sync the changed made to the patient record
        this.$store.dispatch('encounters/setSynced', false)
      }

      this.$store.dispatch('route/back')
    },
    editPatient() {
      // This is '$router.replace' instead of a '$router.push' so '$router.back' directs to the previous page
      this.$router.replace({ name: 'edit-patient', query: { id: this.patient.id } })
    },
    findDuplicatePatients() {
      const duplicates = this.$store.getters['patients/getDuplicates'](this.patient)

      if (duplicates.length === 1) {
        // Found exactly one matching patient, view patient profile
        this.$root.confirm({
          title: 'Duplicate Patient Found',
          body: 'Proceeding to view existing patient information.',
          cancel: false,
        }).then(() => {
          this.$router.push({ name: 'view-patient', query: { id: duplicates[0].id } })
        })
      } if (duplicates.length > 1) {
        // Found multiple matching patients, view results
        this.$root.confirm({
          title: 'Multiple Patients Found',
          body: 'Multiple matching patient profiles found, showing matching results.',
          cancel: false,
        }).then(() => {
          this.$router.push({
            name: 'list-patients',
            query: {
              first_name: this.patient.first_name,
              last_name: this.patient.last_name,
              dob: this.patient.dob,
              dob_us: this.patient.dob_us,
              ssn_last_4: this.patient.ssn_last_4,
            },
          })
        })
      }

      return !!duplicates.length
    },
    trimName() {
      this.patient.first_name = this.patient.first_name.trim()
      this.patient.middle_name = this.patient.middle_name.trim()
      this.patient.last_name = this.patient.last_name.trim()
      this.patient.suffix = this.patient.suffix.trim()
    },
    setMiddleInitial() {
      this.patient.middle_initial = (this.patient.middle_name || '').charAt(0)
    },
    updatePatientProfile() {
      this.trimName()

      if (!this.findDuplicatePatients()) {
        // Update patient and return to patient list
        this.patient.updated_core = true
        this.$store.commit('patients/updatePatient', this.patient)
        if (this.online) {
          this.$store.dispatch('encounters/syncingModal', true)
          this.$store.dispatch('auth/refresh')
          this.$store.dispatch('patients/syncPatient', this.patient)
            .then(response => {
              if (response.responseData[0].status === 'Success') {
                // Flag updated_core to prevent provider from changing decease status once it's synced.
                this.patient.updated_core = false

                // Update the store to change the flag for core patient sync success
                this.$store.commit('patients/updatePatient', this.patient)
                this.updatedPatient()
              }
            })
            .finally(() => {
              this.$store.dispatch('encounters/syncingModal', false)
            })
        } else {
          this.updatedPatient()
        }
      }
    },
    createPatientProfile() {
      this.trimName()

      if (!this.findDuplicatePatients()) {
        this.$store.commit('patients/addPatient', this.patient)
        this.patient.id = this.$store.getters['patients/getLastId']
        if (this.online) {
          this.$store.dispatch('encounters/syncingModal', true)
          this.$store.dispatch('auth/refresh')
          this.$store.dispatch('patients/syncPatient', this.patient)
            .then(response => {
              if (response.responseData[0].status === 'Success') {
                this.createdPatientProfile(this.patient.id)
              } else if (response.responseData[0].status === 'Error' && response.responseData[0].message.indexOf('Duplicate patient') !== -1) {
                // Found duplicate patient!
                this.$store.commit('patients/deletePatient', this.patient.id)

                // Get facility name of local patient
                const localFacility = this.$store.getters['facilities/getById'](this.patient.place_of_service_id)?.title

                // Get facility name of existing patient
                const duplicatePatients = response.responseData[0].dupPatient

                this.duplicatePatientConfirmation(
                  { ...this.patient, facility: localFacility },
                  duplicatePatients,
                ).then(duplicateResponse => {
                  if (duplicateResponse.id) {
                    const existingId = this.resolveDuplicatePatientRecord(duplicateResponse.originalValue)

                    if (existingId !== false) {
                      this.createdPatientProfile(existingId)
                    } else {
                      this.errorDialog('Could not add encounter to local database!')
                    }
                  } else {
                    // Force adding as new patient
                    this.patient.force_add = true
                    this.createPatientProfile()
                  }
                })
              }
            })
            .finally(() => {
              this.$store.dispatch('encounters/syncingModal', false)
            })
        } else {
          this.createdPatientProfile(this.patient.id)
        }
      }
    },
  },
}
</script>
